<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Rich Menu</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <stepForm :dialog="dialog" :form.sync="form" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:header.name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.preview="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.showPeriod="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span style="font-weight: 600">{{ item.name }}</span>
            </template>
            <template v-slot:[`item.preview`]="{ item }">
              <v-img :src="item.preview" width="150" class="mx-auto ma-2" />
            </template>
            <template v-slot:[`item.showPeriod`]="{ item }">
              <span style="font-weight: 600">{{ item.showPeriod }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-switch
                v-if="item.status"
                inset
                color="#1A347F"
                v-model="item.status"
                @change="Status(item)"
              />
              <v-switch
                v-if="item.status === false || !item.status"
                inset
                color="#1A347F"
                v-model="item.status"
                @change="Status(item)"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-btn
                  @click="UpdateMenu(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="DeleteMenu(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl ml-2"
                  style="color: #4f4f4f"
                >
                  Delete
                </v-btn>
              </v-row>
              <!-- <v-icon @click="viewMenu(item)">mdi-eye</v-icon> -->
              <!-- <v-icon @click="UpdateMenu(item)" class="mx-2">mdi-pencil</v-icon>
              <v-icon @click="DeleteMenu(item)">mdi-delete</v-icon> -->
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
    <v-row>
      <footerPage />
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepForm";
import footerPage from "@/components/Shared/footer";
export default {
  components: {
    draggable,
    Loading,
    stepForm,
    footerPage,
  },
  data() {
    return {
      dialog: false,
      headers: [
        // { text: "", value: "", align: "center", width: "20" },
        // { text: "ลำดับ", value: "count", align: "center" },
        { text: "Name", value: "name", align: "center" },
        { text: "Preview", value: "preview", align: "center" },
        { text: "Show Period", value: "showPeriod", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [
        {
          name: "Standard Large Animal x6",
          preview: require("@/assets/images/rich-menu.png"),
          showPeriod: "2022-02-23 - 2022-02-23",
          status: true,
        },
        {
          name: "Swine x6",
          preview: require("@/assets/images/rich-menu1.jpg"),
          showPeriod: "2022-02-23 - 2022-02-23",
          status: false,
        },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
      form: {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        menufrom: false,
        publish: false,
      },
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBiAdmin"))
    );
    this.getAlltype();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/types/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAlltype() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/types`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAlltype", response.data);
      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      //   //console.log(this.list);
      // }
    },
    UpdateMenu(val) {
      localStorage.setItem("Typedata", Encode.encode(val));
      this.$router.push("EditMenu");
    },
    ViewMenu(val) {
      localStorage.setItem("Typedata", Encode.encode(val));
      this.$router.push("ViewMenu");
    },
    async DeleteMenu(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/typeszzz/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlltype();
        }
      });
    },
    goToCreate() {
      this.$router.push("createMenu");
    },
  },
};
</script>
