<template>
  <div>
    <ManageMenu />
  </div>
</template>
<script>
import ManageMenu from "@/components/Menu/ManageMenu";
export default {
  components: {
    ManageMenu,
  },
  created() {},
};
</script>
