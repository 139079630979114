var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"fn24-weight7"},[_vm._v("Rich Menu")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('stepForm',{attrs:{"dialog":_vm.dialog,"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.preview",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.showPeriod",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"mx-auto ma-2",attrs:{"src":item.preview,"width":"150"}})]}},{key:"item.showPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.showPeriod))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-switch',{attrs:{"inset":"","color":"#1A347F"},on:{"change":function($event){return _vm.Status(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e(),(item.status === false || !item.status)?_c('v-switch',{attrs:{"inset":"","color":"#1A347F"},on:{"change":function($event){return _vm.Status(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.UpdateMenu(item)}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl ml-2",staticStyle:{"color":"#4f4f4f"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.DeleteMenu(item)}}},[_vm._v(" Delete ")])],1)]}}],null,true)})],1)],1)],1),_c('v-row',[_c('footerPage')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }