<template>
  <v-dialog v-model="dialog" max-width="900px">
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          New Banner
        </span>
      </v-btn>
    </template> -->
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">New Banner หกหกหก</div>
        </v-col>

        <v-col cols="6" class="text-right" align="center" justify="end">
          <!-- <v-btn
            @click="submit()"
            outlined
            class="rounded-xl"
            style="color: #1a347f"
          >
            <span class="text-center fn14-weight7"> Save Draft </span>
          </v-btn> -->
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Name, Publish Date
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="6" class="pb-0"> Banner Name*: </v-col>
              <v-col cols="6" class="pb-0">Publish Date*:</v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="form.banner_title"
                  hide-details
                  outlined
                  dense
                  placeholder="type your title here"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-row align="center" justify="center">
                  <v-col cols="6">
                    <v-menu
                      v-model="form.menufrom"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.period_start"
                          label="from"
                          hide-details
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          :rules="rules.required"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.period_start"
                        @input="form.menufrom = false"
                        :min="form.period_start"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="form.menuto"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.period_end"
                          label="to"
                          hide-details
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          :rules="rules.required"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.period_end"
                        @input="form.menuto = false"
                        :min="form.period_start"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pb-0">URL Link*:</v-col>
              <v-col cols="6" class="mb-4">
                <v-text-field
                  v-model="form.bannerUrl"
                  outlined
                  dense
                  hide-details
                  placeholder="type your URL here"
                  :rules="rules.required"
                />
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="doRequire()"
              :disabled="
                !form.banner_title || !form.period_start || !form.bannerUrl
              "
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            Add Images & Preview
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-col cols="12" class="pa-0">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12" class="mt-5 ma-10" id="step-3">
                  <v-img
                    v-model="form.bannerImg"
                    @click="changePic1()"
                    src="@/assets/icons/upload.png"
                    class="mr-3 d-inline-block"
                    max-width="18"
                    max-height="22"
                  >
                  </v-img>
                  <span
                    style="color: #1a347f"
                    class="fn16-weight7"
                    @click="changePic1()"
                  >
                    Upload files
                  </span>
                  <br />
                  <span style="color: red" class="fn14"
                    >ใช้ขนาด 1920 x 1080 px</span
                  >
                  <v-col
                    cols="12"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/*"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
              </v-row>
            </v-col>
            <v-btn
              color="primary"
              class="mr-2"
              @click="start = 3"
              :disabled="!showImage"
            >
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <!-- <v-col cols="12">
          <v-checkbox
            v-model="form.publish"
            label="Publish this banner immediately"
            color="indigo"
            hide-details
            class="mx-8"
          />
        </v-col> -->
        <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            :dark="start < 3 ? false : true"
            :disabled="start < 3"
            class="rounded-xl"
            :style="
              start < 3
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit()"
          >
            <span class="text-center fn14-weight7"> Upload </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    form: Object,
    isEdit: {
      type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      checkValidate: true,
      lazy: false,
      dialog: false,
      start: 1,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      bannerImg: "",
      showImage: "",
      imageType: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      idEdit: "",
      selectedType: "",
    };
  },
  watch: {
    "form.date"(val) {
      console.log("val[0]", val[0]);
      console.log("val[1]", val[1]);
    },
    items: {
      handler(val) {
        if (this.isEdit === "edit") {
          this.getRewardById(val.id);
          console.log(val.id);
        }
      },
      deeps: true,
    },
  },
  // computed: {
  //   "form.showdate"() {
  //     return this.form.showDate[0], this.form.showDate[1];
  //   },
  // },

  methods: {
    doRequire() {
      if (this.$refs.createForm.validate(true)) {
        this.start = 2;
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };

        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/banner/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
          auth
        );

        // console.log("response", response);
        // console.log("url", response.data.url);
        const responseUrl = await this.axios.put(
          `${response.data.url}`,
          element
        );
        console.log("responseUrl", element.type);
        this.imageType = element.type;
        this.showImage = response.data.fullUrl;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.bannerImg.splice(1, 1);
    },

    cancel() {
      this.start = 1;
      this.showImage = "";
      this.$emit("close");

      if (this.isEdit !== "edit") {
        this.$emit("clear");
      }
    },
    async submit() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      // period_end: null;
      // period_start: null;
      // publish_date: null;
      this.form = {
        ...this.form,
        // period_start: this.form.period_start,
        // period_end: this.form.period_end,
        bannerImg: this.showImage,
        type: this.imageType,
        usertype: selectedType,
      };
      if (this.isEdit === "edit") {
        this.form = {
          ...this.form,
          id: this.idEdit,
        };
      }
      if (this.isEdit === "edit") {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/banner/${this.idEdit}`,
          this.form,
          auth
        );
        console.log("edit", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `แก้ไขแบนเนอร์สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllbanners");
          this.$router.push("Managebanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขแบนเนอร์ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("clear");
        this.$emit("close");
      } else {
        this.form = {
          ...this.form,
          // period_start: this.form.period_start,
          // period_end: this.form.period_end,
          bannerImg: this.showImage,
          type: this.imageType,
          usertype: selectedType,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/banner`,
          this.form,
          auth
        );
        console.log("creat", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `สร้างแบนเนอร์สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllbanners");
          // this.$router.push("Managebanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างแบนเนอร์ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("clear");
        this.$emit("close");
      }
      // }

      // this.form = {
      //   banner_title: "",
      //   date: "",
      //   bannerUrl: "",
      //   bannerImg: "",
      //   menufrom: false,
      //   publish: false,
      // };
    },

    async getRewardById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banner/` + id
      );
      const { data } = response.data;
      console.log("responseById", data);
      this.form.banner_title = data.banner_title;
      this.form.period_start = data.period_start.substr(0, 10);
      this.form.period_end = data.period_end.substr(0, 10);

      this.form.bannerImg = data.bannerImg;
      this.form.bannerUrl = data.bannerUrl;
      this.idEdit = data.id;
      this.showImage = data.bannerImg;
      console.log("dataStart", data.period_start);
      console.log("dataEnd", data.period_end);
    },
    convertDate(val) {
      return moment(val).format("MM-DD-YYYY");
    },
  },
};
</script>
